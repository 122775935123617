.spinner {
    width: 100%;
    height: 100%;
  }
  
  .spinner > div {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }