
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
background-color: #aab7d1 !important;
}
table.dataTable tbody tr {
background-color: #aab7d1 !important;
}
table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
background-color: #aab7d1 !important;
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
background-color: #aab7d1 !important;
}
table.dataTable.display tbody tr:hover>.sorting_1, table.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
background-color: #aab7d1 !important;
}
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
background-color: #aab7d1 !important;
}
table.dataTable.stripe>tbody>tr.odd.selected>, table.dataTable.display>tbody>tr.odd.selected> {
box-shadow: unset !important;
}
table.dataTable.hover>tbody>tr.selected:hover>, table.dataTable.display>tbody>tr.selected:hover> {
box-shadow: unset !important;
}
table.dataTable tbody tr.selected>* {
color: #111 !important;
}
table.dataTable.display>tbody>tr.odd.selected>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.odd.selected>.sorting_1 {
box-shadow: unset !important;
}
table.dataTable.display tbody tr:hover.selected {
background-color: #aab7d1 !important;
}
table.dataTable tbody tr.selected>* {
box-shadow: unset !important;
}
table.dataTable.display>tbody>tr.even.selected>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.even.selected>.sorting_1 { 
box-shadow: unset !important;
}
table.dataTable.display tbody tr:hover.selected>.sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
box-shadow: unset !important;
}
table.dataTable.display tbody tr.odd.selected {
background-color: #f0f2f4 !important;
}

table.dataTable tbody tr.selected {
background-color: #f0f2f4 !important;
}
table.dataTable.display tbody tr.odd.selected>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
background-color: #f0f2f4;
}
table.dataTable.display tbody tr:hover.selected>.sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
background-color: #f0f2f4 !important;
}
table.dataTable.display tbody tr.even.selected>.sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1 {
background-color: #f0f2f4 !important;
}
table.dataTable tbody th, table.dataTable tbody td {
    padding: 3px 10px;
}


table.dataTable.display tbody tr.odd.selected>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
     background-color: unset !important
}
tr#GbKN-edIpqhNXH_iItk05w {

color: rgb(255 255 255 / 90%);
font-size: 18px;
font-weight: 600;
}
.dataTables_wrapper {
margin: 25px;
margin-top: -45px;
}

tr.head-style {
    background-color: #acbad4 !important;
}
.mar-t h4 {
    font-weight: 600;
}

.bot-table{
    margin-bottom:50px;
}
.top-table{
    padding-top:10px;
}

.inner-scn {
    margin-bottom: 60px !important;
}

@media screen and (max-width: 325px){
    .footer-container {padding: 40px 15px;}
.container {padding: 20px;}
#root {margin-bottom: 60px;}
.inner-scn {margin-bottom: 115px !important;}
}

@media screen and (max-width: 575px){
   
    .inner-scn {margin-bottom: 115px !important;}
    
}