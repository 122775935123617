label {
  font-weight: unset !important;
  font-family: unset !important;
  font-size: 14px;
}

.float-right {
  float: right;
}

.login{
  padding-top: 85px;
    margin-left: 90px;
}
.account-create .form-group {
  padding-top: 85px;
  margin-left: 90px;
}
.mar-bot-100{
  padding-bottom: 100px !important;
}

.spinner {
  width: 100%;
  height: 100%;
}
.spinner-top{
  padding-top: 35%;

}

.spinner > div {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 991px){
  .login {
      margin-left: 55px;
  }
  .account-create .form-group {
    margin-left: 55px;
}
  }
  @media only screen and (max-width: 767px){
  .login{
      margin-left: 75px;
  }
  .account-create .form-group {
    margin-left: 75px;
}
  }
  @media only screen and (max-width: 575px){
  .login {
      margin-left: 100px;
  }
  .account-create .form-group {
    margin-left: 100px;
}

  }
  @media only screen and (max-width: 475px){
  .login {
      margin-left: 70px;
  }
  .account-create .form-group {
    margin-left: 70px;
}
  }
  @media only screen and (max-width: 425px){
  .login {
      margin-left: 40px;
  }
  .account-create .form-group {
    margin-left: 40px;
}
  }
  @media only screen and (max-width: 375px){
  .login {
      margin-left: 20px;
  }
  .account-create .form-group {
    margin-left: 20px;
}
  }