a:focus{outline:0;}
.btn:hover{background:linear-gradient(to bottom,#55697b,#2d2d2d)!important;}
@media only screen and (max-width:767px){
.login-screen{min-height:auto;}
.logo-login{display:none;}
.login-inner{border-right:1px solid #fff;}
}
.account-create {border-top-right-radius: 22px; border-bottom-right-radius: 22px;}
#main_content{margin-top:110px!important;}
.btn:hover{background:linear-gradient(to bottom,#8fa9f9,#1540c5);color:#fff;}
label.error{width:100%;height:auto;padding-left:10px;}
.login-back{background-image: unset; background: white;}
.login-top{padding-top:10%;padding-bottom:10%;}
.login-inner{background-color: #191f39; color: #fff; border-bottom-left-radius: 22px; border-top-left-radius: 22px; box-shadow:rgb(100 100 111 / 20%) 0px 7px 29px 0px; padding: 60px 40px 0}
.logo-login{margin-bottom:30px;}
.login-P-text{color: #ffffff; font-size: 18px;}
.account-button{background:0;border:1px solid #fff;margin-top:26px;margin-bottom:26px;text-align:left;border-radius:30px;width:100%;padding:10px;display:inherit;color:#fff;text-align: center; max-width: 210px;}
.login-back h4{margin-bottom:24px;font-weight:500;font-size:19px;}
.account-button:hover{color: #fff;background: #21a1de; border: 1px solid #21a1de;box-shadow: none;cursor: pointer;}
.login-back h2{font-size:26px;margin-bottom:10px;font-weight:500;}
.account-create{background-color:#fff;padding:25px;border-right:1px solid #fff;border-top:1px solid #fff;border-bottom:2px solid #fff;}
.account-create .form-group{margin-bottom:2px;margin-top:0;font-weight:100;font-size:12px;}
.account-create .form-group input[type=text],.account-create .form-group input[type=password]{background-color:white!important;font-size:12px;font-weight:500;padding-left:20px!important;}
.account-create button{box-shadow:none;border:0;padding-left:20px;padding-right:20px; min-width: 210px; border-radius: 30px; padding: 10px; background-color: #191f39}
.login-top .btn:hover {background: linear-gradient(to bottom,#21a1de,#21a1de)!important;}
.login-screen{min-height:365px; box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;}
.login-screen .form-control,.login-screen input[type=password]{min-height:50px; padding-left: 20px!important;}
.mar-bot-30{margin-bottom:30px!important;}
.mar-top-30{margin-top:30px!important;}
.forgot{color:#000;font-size:15px;}
.forgot:hover{color:#21a1de;cursor: pointer;}
a:focus{color:#21a1de!important;}
form p{margin-bottom:10px;text-align:left;color:#21a1de;font-size:13px;}
.nopad{padding:0;}

form label.error{display:block;margin-bottom:10px;color:red;font-weight:300;font-size:12px;}
footer {padding: 5px 0 0px !important;}
.cpyright {margin-bottom: 5px !important;}
.swcm-img {float: right;}
label {margin-bottom: 10px; font-weight: unset;}
.login-screen .form-control, .login-screen input[type=password] {min-height: 50px; border-radius: 35px;}
.login-screen hr {border-top: 1px solid rgb(255 255 255 / 91%); margin-top: 1.5rem; margin-bottom: 3rem;}
.login-screen img {padding-top: 20px;}
body {background-color: #ffffff;}
.account-bm-button {display: block; padding-bottom: 10px; color: white;}
.account-bm-button:hover{color: #007bff;cursor: pointer;}
input[type=text]{background-color:#fff!important;color:#616161;}
a:focus{color:#fff!important;}
a:focus{color:#abc8ef!important;}
@media screen and (min-width:768px){
	.row-eq-height{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
}
@media screen and (max-width:767px){
	#main_content{margin-top:55px;}
	.login-screen {margin-top: unset; min-height: auto; display: table;}
	.account-create {border-bottom-right-radius: 22px; border-bottom-left-radius: 22px; border-top-right-radius: unset;}
	.login-inner {border-top-left-radius: 22px; border-top-right-radius: 22px; border-bottom-left-radius: unset;}
	.login-screen img {padding-bottom: 20px;}
	.login-top {padding-bottom: 20%;}
}
@media screen and (max-width:475px){
	.login-top {padding-bottom: 30%;}
	.login-screen img {float: unset; margin: auto; display: block;}
}
