.top-bar .btn{background:linear-gradient(to bottom,#ebebeb,#ebebeb) !important;color: #000 !important;}
.top-bar .btn:hover, .navbar-nav li a:hover{background:linear-gradient(to bottom,#ebebeb,#ebebeb) !important;color: #000 !important;}
.navbar-nav li .rt-list:hover{background:linear-gradient(to bottom,#21a1de,#21a1de) !important;color: #fff !important;}
.btn-clr {
    background-color: #007bff;
    border-radius: 3px;
    padding: 5px 15px !important;
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-color: #007bff;
    margin-left: 4px;
    
}
.master-dropdown {
margin-left: -2px;
width:250px;

}
.master-dropdown-first{
    margin-left: -2px; 
    width:max-content;
}
/* .hide-m-v{
    margin-right: -15px;
    margin-top: 30px;
    margin-left: 0px;
}
.scrum{
margin-top: 25px;
margin-right: 1px;
margin-left: -19px;
} */
 


#divLoading {display : none; position : fixed; z-index: 3; background-image : url('https://tasks.pagematics.com/p/smark/r/images/modal-loading.gif'); background-color:#666; opacity : 0.4; background-repeat : no-repeat; background-position : center; left : 0; bottom : 0; right : 0; top : 0;}
.hidden-class {display : none;}
button#imp-biz {float: right;}
a#editLoc {float: right;}
.non-bg {background-color: unset; margin-bottom: 10px;}
.head-txt h4 {color: #ebebeb; font-size: 32px; text-align: center;font-weight: 600; margin-top: 5px;}
.report-container {
    padding: 0px;
}

.fxt-bx h4 {
color: #191f39;
}
.container {
padding: 40px;
padding-bottom:1px;

}

    
.fxt-bx {
text-align: center;
padding: 20px;
}
.btn-secondary {
color: #212529;
background-color: #ebebeb;
border-color: #dae0e5;
}
.n-btn {
padding-right: 310px;
}
img.rounded.float-left {
margin-left: 0px !important;
margin-top: -50px;
}
.run-img a{
margin-right: -400px;
}
.run {
margin-right: 400px;
}
footer {
position: unset;
}

.report-tittle h5 {
color: white;
margin-right: 10px;
}
.report-image img {
padding:0px;
margin-left: 55px;
margin-top: -20px;

}
.mar-t{
    margin-top:25px;
}
.mar-bt30 {
    margin-bottom: 30px;
    margin-top:-40px;
}
.mar-report{
margin-top:-40px;
}
.report-images{
display:flex;
justify-content:center;
align-items: center;
}
img.report-3 {
width: 100%;
margin-left: 40px !important;
padding: 0px;
}
.rprt-3 img {
padding: 25px;
margin-left: 0px;
margin-top: 70px;
}
.btn-img {
margin-left: -700px;
}
.btn-img img {
    margin-top: 1px;
    margin-left: 750px;
    margin-bottom:10px;
    margin-right:10px;
}
.rt-list.add-form-btn {
background-color: #ebebeb;
border-radius: 3px;
padding: 7px 15px;
color: #191f39;
font-size: 14px;
margin: 0 1px;
font-weight: 600;
font-family: "Muli", sans-serif;
}
.fxt-bx p {
margin-top: -23px;
color: #191f39;
}
.report-image-4 img {
margin-left: 335px;
}
.report {
    margin-right:3px;

}

.header-scn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 40px;
}
.image-responsive img {
    margin: auto;
    width: 90%;
    display: block;
}
.hd-image {
    margin: 0 10px;
}
.header-title h4 {
    font-weight: 600;
}




.report-result {margin-bottom: 100px;}


@media screen and (max-width: 1280px){
    .style-row {margin-right: -95px; margin-left: -95px;}
    .master-dropdown {
        margin-left: -2px;
        width:min-content !important;
        
        }
        .master-dropdown-first{
            margin-left: -2px; 
            width:max-content;
        }
    
}
@media screen and (max-width: 1200px){
    .style-row {margin-right: -60px; margin-left: -60px;}
    .master-dropdown {
        margin-left: -2px;
        width:min-content !important;
        
        }
        .master-dropdown-first{
            margin-left: -2px; 
            width:max-content;
        }
    
}
@media screen and (max-width: 992px){
    .style-row {margin-right: -45px; margin-left: -45px;}
    

}
@media screen and (max-width: 1024px){
    .report-image img {margin-left: 90px;}
    .report-image-4 img {margin-left: 215px !important;}
    .master-dropdown {
        margin-left: -2px;
        width:min-content !important;
        
        }
        .master-dropdown-first{
            margin-left: -2px; 
            width:max-content;
        }
    
}
@media screen and (max-width: 991px){
    .report-image img {margin-left: 0px !important; width: 100%;}
    .report-image-4 img {margin-left: 0px !important;}
    .header-scn {flex-direction: column;}
.header-scn img {margin-bottom: 12px; width: 80%;}
.scrum{
    width: 280px !important;
}
.report {
    padding-top: 10px;

}
}
@media screen and (max-width: 767px){
    .user-rpt img {width: 25%;}
    .user-rpt3 img {width: 35%;}
    .run4{margin-left:22px;}
    .footer-container {float: unset; display: flex; justify-content: center; flex-direction: column; align-items: center;}
    .scrum{
        width: 130px !important;
        padding-top: 10px;
    }
    .report {
        padding-top: 10px;
    
    }
}
@media screen and (max-width: 575px){
   
    .footer-container {height: unset; padding: 10px;}
    .scrum{
        width: 130px !important;
        padding-top: 10px;
    }
    .report {
        padding-top: 10px;
    
    }
    
}
@media screen and (max-width: 425px){
    
    .fxt-bx p {margin-left: 0px; font-size: 10px;}
    .btn-img img {margin-left: 749px; width: 85%;}
    .report-images{
        display:block;
    }
    .re-4 img{
        width:60%;
    }
    .scrum{
        width: 130px !important;
        padding-top: 10px;
    }
    .report {
        padding-top: 10px;
    
    }
}
@media screen and (max-width: 375px){
    
    .fxt-bx p {margin-left: 0px; font-size: 10px;}
    .btn-img img {margin-left: 749px; width: 85%;}
    .report-images{
        display:block;
    }
    .re-4 img{
        width:60%;
    }
    .scrum{
        width: 130px !important;
        padding-top: 10px;
    }
    .report {
        padding-top: 10px;
    
    }
    
}
