  label {
    font-weight: 600;
    font-family: 'Fira Sans', sans-serif;
  }
  
  .auth-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    position: absolute;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .form-check-label {
    font-weight: 400;
  }

  .btn-block {
      width: 100%;
  }
  
  .forgot-password {
    font-size: 16px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  
  .forgot-password .btn-link {
    text-decoration: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .forgot-password .btn-link:hover {
    text-decoration: underline;
  }

  .float-right {
    float: right;
  }
  .account-create .form-group, .login {
    padding-top: unset;
    margin-left: unset;
}