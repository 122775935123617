.cpyright {
  float: left;
}
.swcm-img {
  float: right;
}
.footer-container {
  background: #e7e7e7;
  clear: both;
  float: left;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0px;
  height: 35px;
  padding: 0px 15px;
}
@media only screen and (min-width: 375px) and (max-width: 575px) {
  
  .footer-container {height: unset; padding: 10px;}
}
@media only screen and (min-width: 667px) and (max-width: 1024px) {
  .footer-container {
    height: 50px;
  }
}
