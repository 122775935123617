.p-dropdown-panel.p-connected-overlay-enter-done {
  z-index: 99999 !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: normal;
  width: 100%;
  border-bottom: 1px solid gainsboro !important;
  padding: 0.25rem 1rem !important;
}
.p-dropdown-panel .p-inputtext {
  padding: 0rem 0.5rem !important;
}
/* .p-dropdown-panel.p-component.master-dropdown-panel .p-inputtext {
  width: 120px;
}

.p-dropdown-panel.p-component.master-dropdown-panel .p-dropdown-items-wrapper {
  width: 150px;
} */
.p-inputtext .modal-scroll {
  overflow-y: scroll;
  max-height: 460px;
  min-height: 300px;
}
.bdr-required-field {
  border-color: #ff461d !important;
}

* {
  box-sizing: border-box;
}

.bdr-required-field {
  border-color: #ff461d !important;
}
