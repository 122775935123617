

#divLoading {display : none; position : fixed; z-index: 3; background-image : url('https://tasks.pagematics.com/p/smark/r/images/modal-loading.gif'); background-color:#666; opacity : 0.4; background-repeat : no-repeat; background-position : center; left : 0; bottom : 0; right : 0; top : 0;}
.hidden-class {display : none;}
button#imp-biz {float: right;}
a#editLoc {float: right;}
.non-bg {background-color: unset; margin-bottom: 10px;}
.head-txt h4 {color: #ebebeb; font-size: 32px; text-align: center;font-weight: 600; margin-top: 5px;}


.fxt-bx h4 {
color: #191f39;
}
.container {
    padding: 40px;
    }

    
.fxt-bx {
text-align: center;
padding: 20px;
}
.btn-secondary {
color: #212529;
background-color: #ebebeb;
border-color: #dae0e5;
}
.n-btn {
padding-right: 310px;
}
img.rounded.float-left {
margin-left: 0px !important;
margin-top: -50px;
}
.run-img a{
margin-right: -400px;
}
.run {
margin-right: 400px;
}
footer {
position: unset;
}

.report-tittle h5 {
color: white;
margin-right: 10px;
}
.report-image img {
padding:0px;
margin-left: 55px;
margin-top: -20px;

}
img.report-3 {
width: 100%;
margin-left: 40px !important;
padding: 0px;
}
.rprt-3 img {
padding: 25px;
margin-left: 0px;
margin-top: 70px;
}
.btn-img {
margin-left: -700px;
}
.btn-img img {
    margin-top: 1px;
    margin-left: 750px;
    margin-bottom:10px;
    margin-right:10px;
}
.rt-list.add-form-btn {
background-color: #ebebeb;
border-radius: 3px;
padding: 7px 15px;
color: #191f39;
font-size: 14px;
margin: 0 1px;
font-weight: 600;
font-family: "Muli", sans-serif;
}
.fxt-bx p {
margin-top: -10px;
color: #191f39;
}
.report-image-4 img {
margin-left: 245px;
}

.report-3-img {
    
    margin-left: 635px !important;
    margin-bottom: 15px !important;
    
}

.select2-container .select2-selection {
    background-color: #ebebeb !important;
    border: 3px;
    height: calc(1.5em + 0.75rem + 0px);
    margin: 0 5px;
    margin-top: 7px;
    font-size: 13px;
    font-family: "Muli", sans-serif;
    font-weight: 600;
    }
    .p-dropdown {
    background: #fff;
    border: 1px solid #ced4da;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius: 3px;
    }
    .btn-secondary {
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    }
    .report-tb td {
    background-color: #aab7d1;
    }
    .report-tb {
    margin-left: 20px;
    margin-top: -20px;
    }
    .mar-t{
        margin-top:25px;
    }
    


.spinner {
    width: 100%;
    height: 100%;
  }
  
  .spinner > div {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }




  .report-table {   
    padding-top: 75px;

}


  table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #aab7d1 !important;
}
table.dataTable tbody tr {
    background-color: #aab7d1 !important;
}
table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
    background-color: #aab7d1 !important;
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color: #aab7d1 !important;
}
table.dataTable.display tbody tr:hover>.sorting_1, table.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
    background-color: #aab7d1 !important;
}
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background-color: #aab7d1 !important;
}
table.dataTable.stripe>tbody>tr.odd.selected>, table.dataTable.display>tbody>tr.odd.selected> {
    box-shadow: unset !important;
}
table.dataTable.hover>tbody>tr.selected:hover>, table.dataTable.display>tbody>tr.selected:hover> {
    box-shadow: unset !important;
}
table.dataTable tbody tr.selected>* {
    color: #111 !important;
}
table.dataTable.display>tbody>tr.odd.selected>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.odd.selected>.sorting_1 {
    box-shadow: unset !important;
}
table.dataTable.display tbody tr:hover.selected {
    background-color: #aab7d1 !important;
}
table.dataTable tbody tr.selected>* {
    box-shadow: unset !important;
}
table.dataTable.display>tbody>tr.even.selected>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.even.selected>.sorting_1 { 
    box-shadow: unset !important;
}
table.dataTable.display tbody tr:hover.selected>.sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
    box-shadow: unset !important;
}
table.dataTable.display tbody tr.odd.selected {
    background-color: #f0f2f4 !important;
}
table.dataTable.display tbody tr:hover.selected {
    background-color: #f0f2f4 !important;
}
table.dataTable tbody tr.selected {
    background-color: #f0f2f4 !important;
}
table.dataTable.display tbody tr.odd.selected>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
    background-color: #f0f2f4 !important;
}
table.dataTable.display tbody tr:hover.selected>.sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
    background-color: #f0f2f4 !important;
}
table.dataTable.display tbody tr.even.selected>.sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1 {
    background-color: #f0f2f4 !important;
}
table.dataTable tbody th, table.dataTable tbody td {
    padding: 3px 10px;
}
.dataTables_wrapper {
    margin: 25px;
    margin-top: -45px;
    overflow:auto;
}
tr.head-style {
    background-color: #acbad4 !important;
}
.mar-t h4 {
    font-weight: 600;
}



.inner-scn {
    margin-bottom: 60px !important;
}

@media screen and (max-width: 325px){
    .footer-container {padding: 40px 15px;}
.container {padding: 20px;}
#root {margin-bottom: 60px;}
.inner-scn {margin-bottom: 115px !important;}
}

@media screen and (max-width: 575px){
   
    .inner-scn {margin-bottom: 115px !important;}
    
}